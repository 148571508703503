
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Box, Section, Text, Title } from "../components/Core";
import PageHero from "../components/PageHero";
import PageWrapper from "../components/PageWrapper";
import Seo from "../components/Seo";

const ContentImg = styled(Box)`
  box-shadow: ${({ theme }) => `0 42px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  overflow: hidden;
  max-height: 500px;
`;

const Attributions = (props) => {

  const data = useStaticQuery(graphql`
    query {
      simple_succulent: file(relativePath: { eq: "simple_succulent.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      pipes: file(relativePath: { eq: "pipes.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      power_lines: file(relativePath: { eq: "power_lines.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      rowing: file(relativePath: { eq: "rowing.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      rail: file(relativePath: { eq: "rail.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      factory: file(relativePath: { eq: "factory.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      containers: file(relativePath: { eq: "containers.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      cactus: file(relativePath: { eq: "cactus.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      good_vibes_only: file(relativePath: { eq: "good_vibes_only.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      b_mas8_part_one: file(relativePath: { eq: "blog/2021/2021-09-21-do-okd-mas8-part1/start_here.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      b_mas8_part_two: file(relativePath: { eq: "blog/2021/2021-09-28-do-okd-mas8-part2/part_two.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      b_mas8_part_three: file(relativePath: { eq: "blog/2021/2021-10-05-do-okd-mas8-part3/part_three.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      b_rhino_vs_nashorn: file(relativePath: { eq: "blog/2021/2021-10-28-js-nashorn-vs-rhino/nashorn_vs_rhino.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      b_order_chaos: file(relativePath: { eq: "blog/2021/2021-11-03-js-functions/order_chaos.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      b_stay_safe: file(relativePath: { eq: "blog/2021/2021-11-10-js-security/stay_safe.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      b_close_things: file(relativePath: { eq: "blog/2021/2021-11-22-close-things/close_things.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      b_library: file(relativePath: { eq: "blog/2021/2021-11-29-js-invoke-library/library.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      b_planner: file(relativePath: { eq: "blog/2021/2021-12-6-calendar-invite/planner.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      b_logging: file(relativePath: { eq: "blog/2021/2021-12-20-js-logging/logging.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      b_named_params: file(relativePath: { eq: "blog/2021/2021-12-27-js-named-params/named_params.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      b_constants: file(relativePath: { eq: "blog/2022/2022-01-03-mbo-constants/constants.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }  

      b_take_action: file(relativePath: { eq: "blog/2022/2022-01-17-custom-button-action/take_action.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }    

      b_yesno: file(relativePath: { eq: "blog/2022/2022-01-24-yes-no-cancel/yesno.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }       

      b_sqlformat: file(relativePath: { eq: "blog/2022/2022-01-31-sql-format/sqlformat.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }  

      b_script_retrieve_list: file(relativePath: { eq: "blog/2022/2022-02-14-script-retrieve-list/script_retrieve_list.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      b_cors: file(relativePath: { eq: "blog/2022/2022-03-01-cors/cors.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      } 

      b_mxdiff: file(relativePath: { eq: "blog/2022/2022-03-29-mxdiff/mxdiff.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }  

      b_sqlaccess: file(relativePath: { eq: "blog/2022/2022-04-04-sql-access/sqlaccess.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }        
      
      b_refresh_table: file(relativePath: { eq: "blog/2022/2022-04-26-refresh-table/refresh-table.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }  

      t_zebra_print_utility: file(relativePath: { eq: "tools/zebra_print_utility.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }              

      t_vscode_script_extension: file(relativePath: { eq: "tools/vscode_script_extension.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }              

      t_autoscript_dbc: file(relativePath: { eq: "tools/autoscript_dbc.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }  

      t_lets_encrypt_ihs: file(relativePath: { eq: "tools/lets_encrypt_ihs.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      } 
      
      b_ssl: file(relativePath: { eq: "blog/2022/2022-06-07-ssl/ssl.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      } 
      
      b_store_data: file(relativePath: { eq: "blog/2022/2022-08-23-store-data-script/storage.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      } 
      
      b_error_script: file(relativePath: { eq: "blog/2023/2023-01-03-error-script/error-script.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      } 

      b_reverse_proxy: file(relativePath: { eq: "blog/2023/2023-01-24-mas-reverse-proxy/reverse-proxy.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }   

      b_hoisting: file(relativePath: { eq: "blog/2023/2023-05-03-hoisting/hoisting.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }  

      b_excel: file(relativePath: { eq: "blog/2023/2023-05-23-excel/excel.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }    

      b_trust_ca: file(relativePath: { eq: "blog/2023/2023-06-13-trust-ca/trust_ca.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      } 

      b_resource_ids: file(relativePath: { eq: "blog/2023/2023-06-20-resource-ids/resource_ids.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }  

      b_record_updated: file(relativePath: { eq: "blog/2023/2023-07-25-updated-by-another-user/record_updated.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }  

      b_custom_java: file(relativePath: { eq: "blog/2023/2023-09-05-custom-script-classes/custom_java.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }      
     
      t_jobplan_to_inspection: file(relativePath: { eq: "tools/jobplan_to_inspection.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      } 

      b_pipeline: file(relativePath: { eq: "blog/2023/2023-11-07-devops-deploy/pipeline.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      } 
      b_sse: file(relativePath: { eq: "blog/2024/2024-07-09-server-sent-events/sse.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }         
    }
  `)

  return (
    <>
      <PageWrapper>
        <Seo
          title="Sharptree | Attributions | Mobile Reimagined"
          pathname={props.location.pathname}
        />
        <PageHero title="Attributions" />
        <Section>
          <Container>

            <Row className="justify-content-center pb-5">
              <Col lg="9">
                <Title>
                  Legal Attributions
                </Title>
                <Text>
                  IBM&reg; and Maximo&reg; are trademarks and registered trademarks of International Business Machines Corporation, registered in many jurisdictions worldwide.
                </Text>
                <Text className="pt-3">
                  Red Hat&reg; and OpenShift&reg; are registered trademarks of Red Hat, Inc. or its subsidiaries in the United States and other countries.
                </Text>
                <Text className="pt-3">
                  Microsoft Excel&reg; is a registered trademark of Microsoft or its subsidiaries in the United States and other countries.
                </Text>
              </Col>
            </Row>

            <Row className="justify-content-center pb-5">
              <Col lg="9">
                <Title>
                  Image Attributions
                </Title>
                <Text>
                  Thank you to the following people for the use of their images.
                </Text>
              </Col>
            </Row>

            <Row className="justify-content-center pb-5">
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.simple_succulent.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@scottwebb?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Scott Webb</a> on <a href="https://unsplash.com/s/photos/simple?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.pipes.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@victor_g?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Victor Garcia</a> on <a href="https://unsplash.com/s/photos/pipeline?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.power_lines.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@seffen99?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Sven Brandsma</a> on <a href="https://unsplash.com/s/photos/electricity?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
            </Row>

            <Row className="justify-content-center pb-5">
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.rowing.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@joshcala?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Josh Calabrese</a> on <a href="https://unsplash.com/s/photos/rowing?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.rail.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@schxndr?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Kendrew Schexnider</a> on <a href="https://unsplash.com/s/photos/rail?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.factory.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@scienceinhd?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Science in HD</a> on <a href="https://unsplash.com/s/photos/production-line?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
            </Row>

            <Row className="justify-content-center pb-5">
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.cactus.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@bechbox?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Mikkel Bech</a> on <a href="https://unsplash.com/s/photos/cactus?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.containers.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@switch_dtp_fotografie?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Lucas van Oort</a> on <a href="https://unsplash.com/s/photos/containers?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.good_vibes_only.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@markadriane?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Mark Adriane</a> on <a href="https://unsplash.com/s/photos/idea?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
            </Row>

            <Row className="justify-content-center pb-5">
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_mas8_part_one.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@giabyte?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Gia Oris</a> on <a href="https://unsplash.com/s/photos/start?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_mas8_part_two.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@possessedphotography?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Possessed Photography</a> on <a href="https://unsplash.com/s/photos/second?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_mas8_part_three.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@aaronburden?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Aaron Burden</a> on <a href="https://unsplash.com/s/photos/three?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
            </Row>

            <Row className="justify-content-center pb-5">
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_rhino_vs_nashorn.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@jannerboy62?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Nick Fewings</a> on <a href="https://unsplash.com/s/photos/rhinos?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_order_chaos.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@brett_jordan?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Brett Jordan</a> on <a href="https://unsplash.com/s/photos/organized?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_stay_safe.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@jannerboy62?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Nick Fewings</a> on <a href="https://unsplash.com/s/photos/safe?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
            </Row>

            <Row className="justify-content-center pb-5">
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_close_things.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@chuttersnap?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">CHUTTERSNAP</a> on <a href="https://unsplash.com/s/photos/open-windows?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_library.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@freiburgermax?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Max Langelott</a> on <a href="https://unsplash.com/s/photos/modern-library?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_planner.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@2hmedia?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">2H Media</a> on <a href="https://unsplash.com/s/photos/calendar?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
            </Row>

            <Row className="justify-content-center pb-5">
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_logging.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@etiennegirardet?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Etienne Girardet</a> on <a href="https://unsplash.com/s/photos/logging?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_named_params.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@chuttersnap?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">CHUTTERSNAP</a> on <a href="https://unsplash.com/s/photos/names?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_constants.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@pkmfaris?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Faris Mohammed</a> on <a href="https://unsplash.com/s/photos/fibonacci?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
            </Row>

            <Row className="justify-content-center pb-5">
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_take_action.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@gobila?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Moises de Paula</a> on <a href="https://unsplash.com/s/photos/arcade?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_yesno.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@jdiegoph?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Diego PH</a> on <a href="https://unsplash.com/s/photos/take-action?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_sqlformat.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@accrualbowtie?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Ryan Wallace</a> on <a href="https://unsplash.com/s/photos/shape-puzzle?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
            </Row>

            <Row className="justify-content-center pb-5">
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_script_retrieve_list.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@zvessels55?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Zach Vessels</a> on <a href="https://unsplash.com/s/photos/line?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_cors.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@jamessutton_photography?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">James Sutton</a> on <a href="https://unsplash.com/s/photos/access?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_mxdiff.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@jdent?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Jason Dent</a> on <a href="https://unsplash.com/s/photos/compare?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
            </Row>

            <Row className="justify-content-center pb-5">
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_sqlaccess.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@craft_ear?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Jan Tinneberg</a> on <a href="https://unsplash.com/s/photos/open-door?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.t_zebra_print_utility.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@stefyaich?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Stephane YAICH</a> on <a href="https://unsplash.com/s/photos/zebra?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.t_vscode_script_extension.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@synkevych?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Roman Synkevych</a> on <a href="https://unsplash.com/s/photos/code?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
            </Row>

            <Row className="justify-content-center pb-5">
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.t_autoscript_dbc.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@frankiefoto?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">frank mckenna</a> on <a href="https://unsplash.com/s/photos/containers?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_refresh_table.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@joelfilip?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Joel Filipe</a> on <a href="https://unsplash.com/s/photos/data?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_ssl.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@rocinante_11?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Mick Haupt</a> on <a href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
            </Row>

            <Row className="justify-content-center pb-5">
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_store_data.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@ro_ka?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Robert Katzki</a> on <a href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_error_script.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@mahdibafande?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Mahdi Bafande</a> on <a href="https://unsplash.com/photos/qgJ1rt7TeeY?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_reverse_proxy.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@cdd20?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">愚木混株 cdd20</a> on <a href="https://unsplash.com/photos/HQH-GOZ6K2c?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
            </Row>

            <Row className="justify-content-center pb-5">
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_hoisting.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/de/@umityildirim?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Ümit Yıldırım</a> on <a href="https://unsplash.com/photos/9GSIp9QU5R0?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_excel.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@rubaitulazad?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Rubaitul Azad</a> on <a href="https://unsplash.com/s/photos/spreadsheet?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_trust_ca.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@edge2edgemedia?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Edge2Edge Media</a> on <a href="https://unsplash.com/photos/t1OalCBUYRc?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
            </Row>

            <Row className="justify-content-center pb-5">
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_resource_ids.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@markuswinkler?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Markus Winkler</a> on <a href="https://unsplash.com/photos/afW1hht0NSs?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_record_updated.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@timmossholder?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Tim Mossholder</a> on <a href="https://unsplash.com/photos/lNgmT9RokOQ?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_custom_java.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@ckmoyer1?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Christy Moyer</a> on <a href="https://unsplash.com/photos/-5IPpJvbByo?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
            </Row>

            <Row className="justify-content-center pb-5">
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.t_jobplan_to_inspection.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                  Photo by <a href="https://unsplash.com/@the_colourful_pixel?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Sian Cooper</a> on <a href="https://unsplash.com/photos/4kEobPqPgKw?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_pipeline.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                Photo by <a href="https://unsplash.com/@mattpaul?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Matt Paul Catalano</a> on <a href="https://unsplash.com/photos/0QEG_xOoY7Y?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </Text>
              </Col>       
              <Col lg="3" sm="5" className="mb-4">
                <ContentImg>
                  <Img fluid={data.b_sse.childImageSharp.fluid} />
                </ContentImg>
                <Text variant="small" className="pt-4">
                Photo by <a href="https://unsplash.com/@brenna_lynn?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Brenna Huff</a> on <a href="https://unsplash.com/photos/multicolored-horse-pinat-OzKgJ5BP5vU?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
                </Text>
              </Col>                            
            </Row>

          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};

export default Attributions;


